import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Page, Paper } from '../components';
import AuthForm from '../forms/AuthForm/AuthForm';
import { useSelector } from '../store/hooks';
import { useReferalLink } from '../hooks';
import { getToken } from '../store/loginSlice';

import PageContainer from '../components/PageContainer/PageContainer';

const LoginPage: NextPage = () => {
  const token = useSelector(getToken);
  const router = useRouter();
  useReferalLink();

  useEffect(() => {
    const { redirect } = router.query;
    if (redirect && token) {
      router.push(redirect as string);
    } else if (token) {
      router.push('/cabinet');
    }
  });

  return (
    <Page title="ENSYS - Вход">
      <PageContainer contentStyles={{ alignItems: 'center' }}>
        <Paper>
          <AuthForm />
        </Paper>
      </PageContainer>
    </Page>
  );
};

export default LoginPage;
